import Color from "../../assets/colors";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import { styled as styling } from "@mui/material/styles";

// Image Components //
export const ImageStyled = styling("img")((props) => ({
  width: props.$maintenance ? "35%" : null,
  minWidth: props.$maintenance ? "16rem" : null,
  maxWidth: props.$maintenance ? "25%" : null,
  maxWidth: !props.$maintenance && 300,
  maxHeight: !props.$maintenance && 300,
}));

export const Logo = styling("img")({
  //
});

// Typography Components //
export const TitleStyled = styling(Typography)({
  fontWeight: "bold",
  marginTop: "12px",
});

export const SubtitleStyled = styling(Typography)({
  marginTop: "12px",
  padding: "0 10px 0 10px",
});

// Grid Components //
export const GridStyled = styling(Grid)((props) => ({
  cursor: "pointer",

  [props.theme.breakpoints.down("tabletS")]: {
    marginLeft: "7.5%",
  },
}));

export const GridContent = styling(Grid)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  textAlign: "center",
  height: "80vh",
  justifyContent: "center",
});

export const ButtonContainer = styling(Grid)({
  display: "flex",
  alignItems: "center",
  width: "100%",
  flexWrap: "wrap",
  justifyContent: "center",
});

// Appbar Components //
export const AppBarStyled = styling(AppBar)({
  color: Color.hiredlyPurple,
  backgroundColor: Color.white,
});

// Link Component //
export const LinkStyled = styling(Link)({
  textDecoration: "none",

  "&:hover": {
    textDecoration: "none",
  },
});
